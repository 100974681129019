import React from "react";

interface ActionButtonProps {
  onClick: () => void;
  label: string;
}

const ActionButton = ({ onClick, label }: ActionButtonProps) => {
  return (
    <>
      <button
        onClick={onClick}
        className="bg-accent text-brown font-bold py-2 px-4 rounded w-full"
      >
        {label}
      </button>
    </>
  );
};

export default ActionButton;
