/* eslint-disable import/no-anonymous-default-export */

import axios from "../utils/axios";
import ApiTokenSupport from "./token.support";
import {
  DocumentType,
  FullIncomeNote,
} from "../interfaces/incomeNote.interface";
import { ImageItem } from "../components/postNote/ImageUploadPreview";
import { DocumentItem } from "../components/postNote/DocumentUpload";
import { parseAddressComponents } from "../helpers/parseAddressComponents";
import MortgageOrIncomeNote from "../redux/interfaces/mortgageNote.redux.interface";

class IncomeNoteApi extends ApiTokenSupport {
  async fetchIncomeNote(
    noteID: number,
    userID?: number
  ): Promise<FullIncomeNote> {
    const response = await axios.post<FullIncomeNote>(
      `/api/mortgageNotes/incomeNote/fetchById`,
      {
        incomeNoteID: noteID,
        ...(userID && { user_id: userID }),
      },
      this.withAuthorization()
    );
    return response.data;
  }

  async toggleSavedNote(noteID: number, userID: number): Promise<any> {
    const resp = await axios.post(
      `/api/mortgageNotes/savedIncomeNote/toggle`,
      {
        user_id: userID,
        income_note_id: noteID,
      },
      this.withAuthorization()
    );
    return resp.data;
  }

  async post(
    formData: any,
    imageItems: ImageItem[],
    documentItems: DocumentItem[]
  ): Promise<any> {
    // Parse address components
    const addressComponents = formData.addressData?.place_id
      ? await parseAddressComponents(formData.addressData.place_id)
      : null;

    // Create form data for file upload
    const apiFormData = new FormData();

    // Add images with metadata
    const imageMetadata: Record<string, { alt: string }> = {};
    imageItems.forEach((image) => {
      apiFormData.append("images", image.file);
      imageMetadata[image.file.name] = { alt: image.alt };
    });

    // Add documents with metadata
    const documentMetadata: Record<string, { documentType: DocumentType }> = {};
    documentItems.forEach((doc) => {
      apiFormData.append("documents", doc.file);
      documentMetadata[doc.file.name] = {
        documentType: doc.type as DocumentType,
      };
    });

    // Construct the API payload with snake_case keys and proper structure
    const payload = {
      // Address fields from parsed components
      house_number: addressComponents?.houseNumber || "",
      pre_direction: addressComponents?.preDirection || "",
      street: addressComponents?.street || "",
      post_direction: addressComponents?.postDirection || "",
      unit_number: addressComponents?.unitNumber || "",
      unit_type: addressComponents?.unitType || "",
      city: addressComponents?.city || "",
      state: addressComponents?.state || "",
      zip: addressComponents?.zip || "",
      full_address: formData.addressData?.description || "",
      google_place_id: formData.addressData?.place_id || "",

      // Property details
      property_type: formData.propertyType,
      occupancy_status: formData.occupancyStatus,
      performance_status: formData.performanceStatus,
      bedrooms: Number(formData.bedrooms),
      bathrooms: Number(formData.bathrooms),
      square_footage: Number(formData.squareFootage),
      estimated_market_value: Number(
        formData.estimatedMarketValue.replace(/[^\d.]/g, "")
      ),

      // Loan details
      loan_type: formData.loanType,
      note_type: formData.noteType,
      lien_position: formData.lienPosition,
      asking_amount: Number(formData.askingAmount.replace(/[^\d.]/g, "")),
      origination_date: new Date(formData.originationDate),
      maturity_date: new Date(formData.maturityDate),
      initial_loan_amount: Number(
        formData.initialLoanAmount.replace(/[^\d.]/g, "")
      ),
      current_loan_amount: Number(
        formData.currentLoanAmount.replace(/[^\d.]/g, "")
      ),
      loan_payment_amount: Number(
        formData.loanPaymentAmount.replace(/[^\d.]/g, "")
      ),
      total_payoff: Number(formData.totalPayoff.replace(/[^\d.]/g, "")),
      borrower_down_payment: Number(
        formData.borrowerDownPayment.replace(/[^\d.]/g, "")
      ),
      interest_rate: Number(formData.interestRate),
      loan_term: Number(formData.loanTerm),

      // Additional information
      seller_comments: formData.sellerComments,
      image_metadata: imageMetadata,
      document_metadata: documentMetadata,
    };

    console.log("Document Metadata:", documentMetadata);

    apiFormData.append("data", JSON.stringify(payload));

    console.log({ apiFormData });

    const response = await axios.post<any>(
      "/api/mortgageNotes/incomeNote/post",
      apiFormData,
      this.withAuthorization({
        "Content-Type": "multipart/form-data",
      })
    );

    return response.data;
  }

  async fetchByUserId(userID: number): Promise<MortgageOrIncomeNote[]> {
    const response = await axios.post<MortgageOrIncomeNote[]>(
      `/api/mortgageNotes/incomeNote/fetchByUserId`,
      {
        userID,
      },
      this.withAuthorization()
    );
    return response.data;
  }

  async deleteIncomeNote(incomeNoteID: number): Promise<any> {
    const response = await axios.delete<any>(
      `/api/mortgageNotes/incomeNote/delete-note/${incomeNoteID}`,
      this.withAuthorization()
    );
    return response.data;
  }

  async fetchSavedIncomeNotes(userID: number): Promise<any> {
    const response = await axios.post<any>(
      `/api/mortgageNotes/savedIncomeNote/fetchAllSavedNotes`,
      {
        user_id: userID,
      },
      this.withAuthorization()
    );
    return response.data;
  }
}

export default new IncomeNoteApi();
