import { useState, useRef, useCallback } from 'react';
import { Swiper as SwiperClass } from 'swiper';

interface UseSwiperProps {
  initialSlide?: number;
}

const useSwiper = ({ initialSlide = 0 }: UseSwiperProps) => {
  const [swiper, setSwiper] = useState<SwiperClass | null>(null);
  const [activeIndex, setActiveIndex] = useState(initialSlide);
  const swiperRef = useRef<SwiperClass | null>(null);

  const onSwiper = useCallback((swiperInstance: SwiperClass) => {
    setSwiper(swiperInstance);
    swiperRef.current = swiperInstance;
  }, []);

  const goToSlide = useCallback((index: number) => {
    if (swiperRef.current) {
      swiperRef.current.slideTo(index);
    }
  }, []);

  const goToNextSlide = useCallback(() => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
  }, []);

  const goToPrevSlide = useCallback(() => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
    }
  }, []);

  const onSlideChange = useCallback((swiper: SwiperClass) => {
    setActiveIndex(swiper.activeIndex);
  }, []);

  return {
    swiper,
    activeIndex,
    onSwiper,
    goToSlide,
    goToNextSlide,
    goToPrevSlide,
    onSlideChange,
  };
};

export default useSwiper;