import React, { useEffect, useState } from "react";
import useAuth from "../../hooks/useAuth";
import { userApi } from "../../api";
import User from "../../interfaces/user.interface";
import { motion, AnimatePresence } from "framer-motion";
import LoadingSpinner from "../../components/loading/LoadingSpinner";
import useAlert from "../../hooks/useAlert";

interface MyInfoProps {
  props?: any;
}

const MyInfo = ({ props }: MyInfoProps) => {
  const [userData, setUserData] = useState<User | null>(null);
  const [displaySettings, setDisplaySettings] = useState({
    displayName: false,
    displayPhone: false,
    displayEmail: false,
    displayCompany: false,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const { currentUser } = useAuth();

  const { setAlert } = useAlert();

  const fetchUser = async () => {
    if (!currentUser) return;
    const res = await userApi.fetchUserData(currentUser?.id);
    setUserData(res);
    setDisplaySettings({
      displayName: res.display_name,
      displayPhone: res.display_phone,
      displayEmail: res.display_email,
      displayCompany: res.display_company,
    });
  };

  useEffect(() => {
    fetchUser();
  }, [currentUser]);

  const validateInput = (name: string, value: string) => {
    let error = "";
    if (name === "first_name" || name === "last_name") {
      if (!value.trim()) {
        error = "This field cannot be empty.";
      } else if (!/^[a-zA-Z\s]+$/.test(value)) {
        error = "Only letters are allowed.";
      }
    } else if (name === "phone") {
      if (!/^\d*$/.test(value)) {
        error = "Only numbers are allowed.";
      }
    } else if (name === "email") {
      if (!value.trim()) {
        error = "This field cannot be empty.";
      } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
        error = "Invalid email format.";
      }
    }
    return error;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const error = validateInput(name, value);
    setErrors((prev) => ({ ...prev, [name]: error }));
    setUserData((prev) => (prev ? { ...prev, [name]: value } : null));
  };

  const handleToggleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setDisplaySettings((prev) => ({ ...prev, [name]: checked }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const hasErrors = Object.values(errors).some((error) => error);
    if (hasErrors) {
      setAlert({
        message: "Please fix the errors before submitting.",
        type: "error",
        display: true,
      });
      return;
    }
    setIsLoading(true);
    try {
      if (!currentUser) return;
      const updatedUserData = {
        ...userData,
        display_name: displaySettings.displayName,
        display_phone: displaySettings.displayPhone,
        display_email: displaySettings.displayEmail,
        display_company: displaySettings.displayCompany,
      };
      await userApi.saveUserData(currentUser?.id, updatedUserData);
      setAlert({
        message: "User data updated successfully",
        type: "info",
        display: true,
      });
    } catch (error: any) {
      console.error(error);
      setAlert({
        message: error.message || "Failed to post note. Please try again.",
        type: "error",
        display: true,
      });
    } finally {
      setIsLoading(false);
      fetchUser();
    }
  };

  return (
    <div className="flex flex-col gap-4 w-full max-w-2xl mx-auto p-4">
      <div className="text-2xl font-bold">My Info</div>
      {userData && (
        <form className="flex flex-col gap-3" onSubmit={handleSubmit}>
          {["first_name", "last_name", "phone", "email", "company"].map(
            (field) => (
              <div
                key={field}
                className="grid grid-cols-[200px_1fr] gap-4 items-start"
              >
                <label htmlFor={field} className="text-sm font-semibold">
                  {field.replace("_", " ").toUpperCase()}
                </label>
                <div className="flex flex-col gap-1">
                  <input
                    type="text"
                    name={field}
                    id={field}
                    value={String(userData[field as keyof User] || "")}
                    onChange={handleInputChange}
                    className="input input-sm w-full"
                    data-1p-ignore
                  />
                  {errors[field] && (
                    <div className="text-error text-sm">{errors[field]}</div>
                  )}
                  {field === "last_name" && (
                    <div className="flex items-center gap-2">
                      <input
                        type="checkbox"
                        name="displayName"
                        className="checkbox checkbox-xs"
                        checked={displaySettings.displayName}
                        onChange={handleToggleChange}
                      />
                      <label htmlFor="displayName" className="text-sm">
                        Display full name to other users
                      </label>
                    </div>
                  )}
                  {field !== "first_name" && field !== "last_name" && (
                    <div className="flex items-center gap-2">
                      <input
                        type="checkbox"
                        name={`display${
                          field.charAt(0).toUpperCase() + field.slice(1)
                        }`}
                        checked={
                          displaySettings[
                            `display${
                              field.charAt(0).toUpperCase() + field.slice(1)
                            }` as keyof typeof displaySettings
                          ]
                        }
                        className="checkbox checkbox-xs"
                        onChange={handleToggleChange}
                      />
                      <label
                        htmlFor={`display${
                          field.charAt(0).toUpperCase() + field.slice(1)
                        }`}
                        className="text-sm"
                      >
                        Display {field.charAt(0).toLowerCase() + field.slice(1)}{" "}
                        to other users
                      </label>
                    </div>
                  )}
                </div>
              </div>
            )
          )}
          <div className="flex justify-end mt-4">
            <button type="submit" className="btn w-48 btn-accent text-lg">
              {isLoading ? <LoadingSpinner className="w-4 h-4" /> : "Save"}
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default MyInfo;
