import React, { useState } from "react";
import { faBookmark } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { incomeNoteApi, notesApi } from "../../api";
import { FullMortgageNote } from "../../interfaces/mortgageNote.interface";
import useAuth from "../../hooks/useAuth";
import { FullIncomeNote } from "../../interfaces/incomeNote.interface";

interface SavedNoteButtonProps {
  note: FullMortgageNote | FullIncomeNote;
}

const SavedNoteButton = ({ note }: SavedNoteButtonProps) => {
  const [localState, setLocalState] = useState<boolean>(note.is_saved || false);

  const { currentUser } = useAuth();

  const handleClick = async () => {
    if (!currentUser) {
      return;
    }
    setLocalState(!localState);
    let result: boolean;
    if ("loan_number" in note) {
      result = await notesApi.toggleSavedNote(note.id, currentUser.id);
    } else {
      result = await incomeNoteApi.toggleSavedNote(note.id, currentUser.id);
    }
    setLocalState(result);
  };

  return (
    <>
      {currentUser && (
        <button
          className={` ${
            localState
              ? "btn-sm md:btn-md btn btn-accent"
              : "btn-sm md:btn-md btn bg-gray-300  text-white border-none hover:bg-accent hover:text-black"
          }`}
          onClick={handleClick}
        >
          <FontAwesomeIcon
            icon={faBookmark}
            className={` ${localState ? "text-brown" : "text-bg-gray-400"}`}
          />
          <span className={localState ? "text-brown" : "text-bg-gray-400"}>
            {localState ? "SAVED" : "SAVE"}
          </span>
        </button>
      )}
    </>
  );
};

export default SavedNoteButton;
