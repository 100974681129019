import { faTrashCan, faGripDotsVertical } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion, Reorder, AnimatePresence } from "framer-motion";
import React, { useState } from "react";

export interface ImageItem {
  id: string;
  file: File;
  preview: string;
  alt: string;
}

interface ImageUploadPreviewProps {
  images: ImageItem[];
  onChange: (images: ImageItem[]) => void;
}

export const ImageUploadPreview = ({
  images,
  onChange,
}: ImageUploadPreviewProps) => {
  const [hoveredId, setHoveredId] = useState<string | null>(null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files || []);
    const newImages: ImageItem[] = files.map((file) => ({
      id: Math.random().toString(36),
      file,
      preview: URL.createObjectURL(file),
      alt: "",
    }));

    onChange([...images, ...newImages]);
  };

  const handleAltChange = (id: string, alt: string) => {
    onChange(images.map((img) => (img.id === id ? { ...img, alt } : img)));
  };

  const handleRemove = (id: string) => {
    onChange(images.filter((img) => img.id !== id));
  };

  return (
    <div className="space-y-2">
      <input
        type="file"
        accept="image/*"
        multiple
        onChange={handleFileChange}
        className="file-input file-input-bordered file-input-accent file-input-sm w-full"
      />

      <Reorder.Group axis="y" values={images} onReorder={onChange}>
        {images.map((image) => (
          <Reorder.Item key={image.id} value={image}>
            <motion.div
              layout
              className="flex items-center gap-4 p-2 border rounded-lg mb-2 relative group"
              onMouseEnter={() => setHoveredId(image.id)}
              onMouseLeave={() => setHoveredId(null)}
            >
              <AnimatePresence>
                {hoveredId === image.id && (
                  <motion.div
                    initial={{ opacity: 0, x: 20 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: 20 }}
                    transition={{ duration: 0.2 }}
                    className="absolute right-full mr-2 text-gray-400 border rounded-md p-1 cursor-grab active:cursor-grabbing"
                  >
                    <FontAwesomeIcon icon={faGripDotsVertical} size="lg" />
                  </motion.div>
                )}
              </AnimatePresence>
              <img
                src={image.preview}
                alt={image.alt}
                className="w-20 h-20 object-cover rounded"
              />
              <input
                type="text"
                placeholder="Image description"
                value={image.alt}
                onChange={(e) => handleAltChange(image.id, e.target.value)}
                className="input input-sm flex-1"
              />
              <button
                onClick={() => handleRemove(image.id)}
                className="btn btn-sm btn-error"
              >
                <FontAwesomeIcon icon={faTrashCan} className="text-white" />
              </button>
            </motion.div>
          </Reorder.Item>
        ))}
      </Reorder.Group>
    </div>
  );
};
