import React from "react";
import { SwiperSlide } from "swiper/react";
import LoadingSpinner from "../../loading/LoadingSpinner";
import { HubspotContactData, HubspotContactErrors } from "./LoginRegisterModal";
import { parsePhone } from "../../../helpers/formatPhoneNumber";

interface ContactSlideProps {
  contactData: HubspotContactData;
  contactErrors: HubspotContactErrors;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  submitData: () => void;
  goToNextSlide: () => void;
  isLoading: boolean;
}

const ContactSlide = ({
  contactData,
  contactErrors,
  handleChange,
  submitData,
  goToNextSlide,
  isLoading,
}: ContactSlideProps) => {
  return (
    <>
      <SwiperSlide>
        <div className="flex flex-col items-center justify-center h-full gap-2 w-full xs:px-4 sm:px-12 md:px-28 ">
          <div className="w-full max-w-96">
            <input
              type="text"
              name="firstName"
              placeholder="First Name"
              className={`input input-bordered ${
                contactErrors.firstName
                  ? "border-red-500"
                  : "border-light-brown"
              } focus:border-light-brown placeholder:text-light-brown bg-white  text-brown w-full`}
              value={contactData.firstName}
              onChange={handleChange}
            />
            {contactErrors.firstName && (
              <p className="text-red-500 text-sm">{contactErrors.firstName}</p>
            )}
          </div>
          <div className="w-full  max-w-96">
            <input
              type="text"
              name="lastName"
              placeholder="Last Name"
              className={`input input-bordered ${
                contactErrors.lastName ? "border-red-500" : "border-light-brown"
              } focus:border-light-brown placeholder:text-light-brown bg-white  text-brown w-full`}
              value={contactData.lastName}
              onChange={handleChange}
            />
            {contactErrors.lastName && (
              <p className="text-red-500 text-sm">{contactErrors.lastName}</p>
            )}
          </div>
          <div className="w-full  max-w-96">
            <input
              type="text"
              name="phone"
              placeholder="Phone Number"
              className={`input input-bordered ${
                contactErrors.phone ? "border-red-500" : "border-light-brown"
              } focus:border-light-brown placeholder:text-light-brown bg-white  text-brown w-full`}
              value={parsePhone(contactData.phone)}
              onChange={handleChange}
            />
            {contactErrors.phone && (
              <p className="text-red-500 text-sm">{contactErrors.phone}</p>
            )}
          </div>
          <div className="w-full  max-w-96">
            <button
              className="btn btn-accent w-full text-brown"
              onClick={submitData}
            >
              {isLoading ? <LoadingSpinner /> : "SUBMIT"}
            </button>
          </div>
        </div>
      </SwiperSlide>
    </>
  );
};

export default ContactSlide;
