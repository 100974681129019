import React, { ReactNode } from "react";

interface NoteInfoCardProps {
  children?: ReactNode;
  header?: string;
}

const NoteInfoCard = ({ children, header }: NoteInfoCardProps) => {
  return (
    <>
      <div className="card rounded-xl border pb-4 border-gray-200">
        <div className="bg-gray-100 rounded-t-xl px-4 py-2">
          <div className="text-brown font-bold">{header}</div>
        </div>
        <div className="px-4">{children}</div>
      </div>
    </>
  );
};

export default NoteInfoCard;
