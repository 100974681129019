import React from "react";
import { SwiperSlide } from "swiper/react";
import LoadingSpinner from "../../loading/LoadingSpinner";

interface RegisterSlideProps {
  email: string;
  emailErrors: { email: string };
  setEmail: (email: string) => void;
  submitEmailHubspot: () => void;
  isLoadingEmail: boolean;
  goToPrevSlide: () => void;
}

const RegisterSlide = ({
  emailErrors,
  email,
  setEmail,
  submitEmailHubspot,
  isLoadingEmail,
  goToPrevSlide,
}: RegisterSlideProps) => {
  return (
    <>
      <SwiperSlide>
        <div className="flex flex-col items-center justify-center h-full gap-2 w-full xs:px-4 sm:px-12 md:px-28 ">
          <div className="w-full max-w-96">
            {" "}
            <input
              type="text"
              placeholder="What's your email?"
              className={`input input-bordered border-light-brown ${
                emailErrors.email ? "border-red-500" : ""
              } focus:border-light-brown bg-white  text-brown w-full`}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {emailErrors.email && (
              <p className="text-red-500 text-sm">{emailErrors.email}</p>
            )}
          </div>
          <div className="w-full max-w-96">
            {" "}
            <button
              className="btn btn-accent w-full text-brown"
              onClick={submitEmailHubspot}
            >
              {isLoadingEmail ? <LoadingSpinner /> : "SUBMIT"}
            </button>
          </div>
          <div className="my-2 mt-6">
            <p className="text-main-gray">
              Already have an account?{" "}
              <span
                className="text-main-gray underline cursor-pointer font-semibold"
                onClick={goToPrevSlide}
              >
                Sign In
              </span>
            </p>
          </div>
        </div>
      </SwiperSlide>
    </>
  );
};

export default RegisterSlide;
