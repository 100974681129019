// import { AxiosRequestHeaders } from "axios";

export default class ApiTokenSupport {
  protected withAuthorization(
    headers?: Record<string, string>,
    token?: string
  ) {
    return {
      headers: {
        ...(headers || {}),
        Authorization: `Bearer ${
          token || localStorage.getItem("x-notes-access-token")
        }`,
      },
    };
  }
}
