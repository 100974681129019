import addCommas from "./addCommas";

const calculateMonthlyReturn = (
  loanAmount: number,
  annualInterestRate: number,
  loanTermInMonths: number
): string => {
  // Calculate the annual interest
  const annualInterest = loanAmount * (annualInterestRate / 100);

  // Calculate the monthly return
  const monthlyReturn = annualInterest / loanTermInMonths;

  return addCommas(Number(parseFloat(monthlyReturn.toFixed(2)))) || "";
};

export { calculateMonthlyReturn };
