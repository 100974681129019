import axios from "axios";
// import { authChannel } from "src/channels/authChannel";


const axiosInt = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});

axiosInt.interceptors.response.use(
  (response: any) => {
    if (
      Array.isArray(response.data) &&
      // response.data[0] === "DENY" &&
      // response.data[1]?.code === 401 &&
      response.data[1]?.type === "invalid token"
    ) {
      console.error(response);
      forceLogout();
      return false;
    }
    return response;
  },
  (error: any) => {
    console.log(error.response);
    if (
      // error?.response?.data?.code === 401 ||
      error?.response?.data?.type === "invalid token" ||
      error?.response?.data?.message.includes("jwt expired") ||
      error?.response?.data?.message.includes("invalid signature")
    ) {
      forceLogout();
    }
    return Promise.reject(
      (error.response && error.response.data) ||
        "Our servers are currently experiencing downtime."
    );
  }
);

const forceLogout = () => {
  localStorage.removeItem("x-access-token");
};

export default axiosInt;
