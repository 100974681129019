import React, { useState } from "react";
import { FullIncomeNote } from "../../interfaces/incomeNote.interface";
import useAuth from "../../hooks/useAuth";
import { incomeNoteApi } from "../../api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrashCan,
  faTriangleExclamation,
} from "@fortawesome/pro-regular-svg-icons";
import useAlert from "../../hooks/useAlert";
import useModal from "../../hooks/useModal";
import { useNavigate } from "react-router-dom";

interface DeleteNoteButtonProps {
  incomeNote: FullIncomeNote;
}

const DeleteNoteButton = ({ incomeNote }: DeleteNoteButtonProps) => {
  const { currentUser } = useAuth();

  const { setAlert } = useAlert();

  const { setShowModal, openModalWith } = useModal();

  const navigate = useNavigate();

  const [isDeleting, setIsDeleting] = useState(false);

  const handleClick = async () => {
    openModalWith({
      title: "",
      body: (
        <div className="text-red-600 font-medium flex flex-row gap-2 text-center items-center justify-center -mt-4 mb-8">
          <FontAwesomeIcon icon={faTriangleExclamation} />
          Are you sure you want to delete this note? This action cannot be
          undone!
        </div>
      ),
      onSubmit: handleDeleteSubmit,
      submitLabel: "Delete",
      submitIcon: faTrashCan,
      submitLoading: isDeleting,
      submitColor: "bg-error hover:bg-red-500",
    });
  };

  const handleDeleteSubmit = async () => {
    if (incomeNote?.id) {
      try {
        setIsDeleting(true);
        const res = await incomeNoteApi.deleteIncomeNote(incomeNote?.id);
        console.log(res);
        setShowModal(false);
        setAlert({
          message: `Note with ID-${incomeNote?.id} has been deleted.`,
          type: "info",
          display: true,
        });
        navigate("/my-notes");
      } catch (error) {
        console.error(error);
        setShowModal(false);
        setAlert({
          message: `Error deleting Note with ID-${incomeNote?.id}.`,
          type: "error",
          display: true,
        });
      } finally {
        setIsDeleting(false);
      }
    }
  };

  return (
    <>
      {currentUser && (
        <button
          className={"btn-sm md:btn-md btn btn-error"}
          onClick={handleClick}
        >
          <FontAwesomeIcon icon={faTrashCan} className={"text-brown"} />
          <span className={"text-brown"}>{"DELETE"}</span>
        </button>
      )}
    </>
  );
};

export default DeleteNoteButton;
