import React from "react";
import { useNavigate } from "react-router-dom";
import ActionButton from "../components/buttons/ActionButton";
import HeaderNav from "../components/nav/HeaderNav";

interface NotFoundProps {
  props?: any;
}

const NotFound = ({ props }: NotFoundProps) => {
  const navigate = useNavigate();
  return (
    <>
      <HeaderNav />
      <div className="flex flex-col items-center justify-start h-screen w-full bg-white -mt-6">
        <div className="flex flex-col items-center justify-center text-brown">
          <h1 className="text-[60px] font-bold">Something went wrong</h1>
          <p className="text-lg">We can't find the page you're looking for.</p>
          <div className="w-48 mt-5 shadow-">
            <ActionButton
              onClick={() => navigate("/")}
              label={"Back to Home"}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default NotFound;
