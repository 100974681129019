import React from "react";
import useModal from "../../hooks/useModal";
import LoginRegisterModal from "../modals/LoginRegisterModal/LoginRegisterModal";

interface PublicNavProps {
  props?: any;
}

const PublicNav = ({ props }: PublicNavProps) => {
  const { openModalWith } = useModal();

  const handleRegister = () => {
    openModalWith({
      title: "",
      body: <LoginRegisterModal initialSlide={1} />,
      hideButtons: true,
    });
  };
  return (
    <>
      <div className="">
        <button
          onClick={handleRegister}
          className="btn btn-accent text-brown font-bold"
        >
          REGISTER
        </button>
      </div>
    </>
  );
};

export default PublicNav;
