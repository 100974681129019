import React from "react";

interface NotesHeaderProps {
  props?: any;
}

const NotesHeader = ({ props }: NotesHeaderProps) => {
  return (
    <>
      <div>
        <div className=" flex flex-col columns-1 gap-4">
          <div>
            <h1 className="text-2xl text-heading-gray font-black">
              WHAT ARE MORTGAGE NOTES?
            </h1>
          </div>
          <div>
            <p className="text-main-gray font-medium">
              Mortgage notes (trust deeds) are an alternative asset class within
              active real estate investing. Notes currently posted below are for
              sale, click on a Note to learn more.
            </p>
          </div>
          {/* <div>
            <h1 className="text-2xl text-heading-gray font-black">
              DUE DILIGENCE
            </h1>
          </div>
          <div>
            <p className="text-main-gray font-medium">
              How Do We Check Each Opportunity for Our Private Lenders? We call
              it the 4-Pronged Approach to a Value-Based Loan. We Check:
            </p>
          </div> */}
        </div>
        {/* <div className="grid grid-cols-1 md:grid-cols-4 gap-4 px-4 py-6 md:py-10">
          {[
            {
              icon: faChartBar,
              title: "DEAL STRUCTURE",
              description: "Are the terms attractive?",
            },
            {
              icon: faLocationDot,
              title: "LOCATION",
              description: "Is this in a valuable and viable area?",
            },
            {
              icon: faPercent,
              title: "LTV",
              description:
                "Does the deal have enough upside to offer protection?",
            },
            {
              icon: faHandHoldingDollar,
              title: "BORROWER",
              description: "Can they service the debt?",
            },
          ].map((item, index) => (
            <div
              key={index}
              className="flex flex-col items-center justify-between p-5 border-2 border-white rounded-lg hover:shadow-xl cursor-default h-full"
            >
              <FontAwesomeIcon
                icon={item.icon}
                className="text-success text-[40px] mb-4"
              />
              <div className="flex flex-col items-center justify-center flex-grow">
                <div className="text-heading-gray text-center font-extrabold text-lg mb-2 h-[35px] flex items-center">
                  {item.title}
                </div>
                <div className="text-main-gray text-center font-base text-base h-[35px] flex items-start">
                  {item.description}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="mb-3 mt-3 md:mt-0">
          <button
            className="btn bg-warning rounded-[4px] border-warning border-2 text-white hover:border-warning hover:text-warning hover:bg-white font-bold w-full cursor-pointer"
            onClick={handleSubscribe}
          >
            SUBSCRIBE TO OUR NOTES NEWSLETTER
          </button>
        </div> */}
      </div>
    </>
  );
};
export default NotesHeader;
