import React from "react";
import LoadingSpinner from "./LoadingSpinner";

interface LoadingTableProps {
  label: string;
  displayLoading?: boolean;
}

const LoadingTable = ({ label, displayLoading = true }: LoadingTableProps) => {
  return (
    <>
      <div className="flex items-center justify-center gap-2 rounded-lg bg-brown px-2 py-2 dark:bg-brown">
        <LoadingSpinner className={"text-accent"} />
        <span className="animate-pulse font-bold text-accent">
          {displayLoading && "Loading "}
          {label}
        </span>
      </div>
    </>
  );
};

export default LoadingTable;
