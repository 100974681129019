import React, { ReactNode } from "react";
import useAuth from "../hooks/useAuth";
import { Navigate, Outlet } from "react-router-dom";
import LoadingTable from "../components/loading/LoadingTable";

interface PrivateRouteProps {
  children?: ReactNode;
}

const PrivateRoute = ({ children }: PrivateRouteProps) => {
  const { currentUser, isLoading } = useAuth();

  if (isLoading) {
    return (
      <div className="flex justify-center items-center w-full h-screen">
        <div className="bg-base-300 rounded-lg px-4 py-1 mb-48">
          <LoadingTable label="" />
        </div>
      </div>
    );
  }

  if (!currentUser) {
    return <Navigate to="/" />;
  }

  return <>{children || <Outlet />}</>;
};

export default PrivateRoute;
