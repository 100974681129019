import { useRoutes } from "react-router-dom";
import { Helmet } from "react-helmet";
import configs from "./configs";
import Notes from "./pages/Notes";
import NoteGuard from "./guards/NoteGuard";
import NotFound from "./pages/NotFound";
import Acknowledgement from "./pages/Acknowledgement";
import PostNote from "./pages/private/PostNote";
import MyNotes from "./pages/private/MyNotes";
import PrivateLayout from "./layouts/PrivateLayout";
import SharedLayout from "./layouts/SharedLayout";
import MyInfo from "./pages/private/MyInfo";
import SavedNotes from "./pages/private/SavedNotes";

const Routes = () => {
  const routing = useRoutes([
    {
      // eslint-disable-next-line react/jsx-no-undef
      element: <SharedLayout />,
      children: [
        {
          path: "/",
          element: (
            <>
              <Helmet>
                <title>{configs.name}</title>
              </Helmet>
              <Notes />
            </>
          ),
        },
        {
          path: "/notes/:noteHash",
          element: (
            <>
              <Helmet>
                <title>{configs.name}</title>
              </Helmet>
              <NoteGuard />
            </>
          ),
        },
        {
          path: "/terms-and-conditions",
          element: (
            <>
              <Acknowledgement />
            </>
          ),
        },
      ],
    },
    {
      element: <PrivateLayout />,
      children: [
        {
          path: "/saved-notes",
          element: (
            <>
              <Helmet>
                <title>{configs.name}</title>
              </Helmet>
              <SavedNotes />
            </>
          ),
        },
        {
          path: "/post-note",
          element: (
            <>
              <Helmet>
                <title>{configs.name}</title>
              </Helmet>
              <PostNote />
            </>
          ),
        },
        {
          path: "/my-notes",
          element: (
            <>
              <Helmet>
                <title>{configs.name}</title>
              </Helmet>
              <MyNotes />
            </>
          ),
        },
        {
          path: "/my-info",
          element: (
            <>
              <Helmet>
                <title>{configs.name}</title>
              </Helmet>
              <MyInfo />
            </>
          ),
        },
      ],
    },
    {
      path: "*",
      element: (
        <>
          <NotFound />
        </>
      ),
    },
  ]);

  return (
    <>
      <Helmet>
        <title>{configs.name || window.location.host}</title>
      </Helmet>
      {routing}
    </>
  );
};

export default Routes;
