import React, { ReactNode } from "react";
import PrivateRoute from "../auth/PrivateRoute";
import { Outlet } from "react-router-dom";
import SharedLayout from "./SharedLayout";

interface PrivateLayoutProps {
  children?: ReactNode;
}

const PrivateLayout = ({ children }: PrivateLayoutProps) => {
  return (
    <>
      <SharedLayout>
        <PrivateRoute>{children || <Outlet />}</PrivateRoute>
      </SharedLayout>
    </>
  );
};

export default PrivateLayout;
