import React from "react";
import { PropertyImage } from "../../interfaces/mortgageNote.interface";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./PropertyImageCarousel.css";
import IncomeNoteImage from "../../interfaces/incomeNoteImage.interface";

interface PropertyImageCarouselProps {
  images: PropertyImage[] | Omit<IncomeNoteImage, "income_note_id">[];
}

const PropertyImageCarousel = ({ images }: PropertyImageCarouselProps) => {
  const sortedImages = [...images].sort((a, b) => {
    if ("order_position" in a && "order_position" in b) {
      return a.order_position - b.order_position;
    }
    if ("order_number" in a && "order_number" in b) {
      return a.order_number - b.order_number;
    }
    return 0;
  });

  return (
    <div className="w-full">
      <Swiper
        modules={[Navigation, Pagination]}
        spaceBetween={10}
        slidesPerView={1}
        loop={true}
        autoplay={true}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}
        pagination={{ clickable: true, el: ".swiper-pagination" }}
      >
        {sortedImages.map((image) => (
          <SwiperSlide key={`image-${image.id}`}>
            <img
              src={"link" in image ? image.link : image.url}
              alt={"name" in image ? image.name : image.alt}
              className="w-full h-96 object-cover rounded-[6px]"
            />
          </SwiperSlide>
        ))}

        {/* Custom navigation buttons */}
        <div
          style={{
            color: "white",
          }}
          className="swiper-button-prev text-white text-3xl absolute left-4 top-1/2 transform -translate-y-1/2 cursor-pointer z-10 opacity-35 hover:opacity-100"
        ></div>
        <div
          style={{
            color: "white",
          }}
          className="swiper-button-next text-white text-3xl absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer z-10 opacity-35 hover:opacity-100"
        ></div>

        {/* Custom pagination dots */}
        <div className="swiper-pagination mt-4"></div>
      </Swiper>
    </div>
  );
};

export default PropertyImageCarousel;
