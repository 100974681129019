import React from "react";
import "./NoteCardSkeleton.css"; // We'll add custom styles here

const NoteCardSkeleton = () => {
  return (
    <div className="card bg-white shadow-xl w-full sm:w-11/12 md:w-5/6 lg:w-full xl:w-11/12 2xl:w-5/6 mx-auto animate-pulse">
      <div className="h-40 sm:h-48 md:h-56 lg:h-64 xl:h-72 2xl:h-80 skeleton-loader rounded-t-xl"></div>
      <div className="p-3 sm:p-4 md:p-5 lg:p-6">
        <div className="h-4 skeleton-loader rounded w-3/4 mb-2"></div>
        <div className="h-4 skeleton-loader rounded w-full mb-4"></div>
        <div className="flex justify-between mb-4">
          <div className="h-8 skeleton-loader rounded w-1/4"></div>
          <div className="h-8 skeleton-loader rounded w-1/4"></div>
          <div className="h-8 skeleton-loader rounded w-1/4"></div>
        </div>
        <div className="h-1 skeleton-loader rounded mb-4"></div>
        <div className="flex justify-between">
          <div className="h-4 skeleton-loader rounded w-1/2"></div>
          <div className="h-4 skeleton-loader rounded w-1/4"></div>
        </div>
      </div>
    </div>
  );
};

export default NoteCardSkeleton;
