import React from "react";
import Note from "../pages/Note";
import { useParams } from "react-router-dom";

interface NoteGuardProps {
  props?: any;
}

const NoteGuard = ({ props }: NoteGuardProps) => {
  const { noteHash } = useParams();

  if (!noteHash) {
    return <></>;
  }

  return (
    <>
      <Note noteHash={noteHash} />
    </>
  );
};

export default NoteGuard;
