/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import useAuth from "../../hooks/useAuth";
import { incomeNoteApi } from "../../api";
import NoteCardGrid from "../../components/notes/NoteCardGrid";
import MortgageOrIncomeNote from "../../redux/interfaces/mortgageNote.redux.interface";

interface MyNotesProps {
  props?: any;
}

const MyNotes = ({ props }: MyNotesProps) => {
  const { currentUser } = useAuth();

  const [myNotes, setMyNotes] = useState<MortgageOrIncomeNote[]>([]);

  const [status, setStatus] = useState<string>("loading");

  const fetchMyNotes = async (showLoading = true) => {
    if (currentUser) {
      if (showLoading) setStatus("loading");
      const res: MortgageOrIncomeNote[] = await incomeNoteApi.fetchByUserId(
        currentUser.id
      );
      setMyNotes(res);
      if (showLoading) setStatus("loaded");
    }
  };

  useEffect(() => {
    fetchMyNotes();
  }, [currentUser]);

  return (
    <>
      <div className="w-full px-4">
        <div className="mb-4">
          <h1 className="text-2xl text-heading-gray font-black">My Notes</h1>
        </div>
        <div className="w-full flex justify-center ">
          <NoteCardGrid
            notes={myNotes}
            status={status}
            hideRibbon={true}
            hideSaved={true}
            fetchSavedNotes={fetchMyNotes}
          />
        </div>
      </div>
    </>
  );
};

export default MyNotes;
