import { NoteType } from "../pages/Note";

const hashNote = (noteID: number, noteType: NoteType): string => {
  return btoa(`${noteID}-${noteType}`);
};

const unhashNote = (hash: string): { noteID: number; noteType: NoteType } => {
  try {
    const decoded = atob(hash);
    const [noteID, noteType] = decoded.split("-");
    return {
      noteID: parseInt(noteID),
      noteType: noteType as NoteType,
    };
  } catch (error) {
    console.error("Failed to decode note hash:", error);
    throw new Error("Invalid note hash");
  }
};

export { hashNote, unhashNote };
