import React from "react";
import { Helmet } from "react-helmet";
import Routes from "./routes";
import "./App.css";
import configs from "./configs";
import ApplicationProvider from "./provider";
import HubSpotTracker from "./components/hubspot/HubSpotTracker";

function App() {
  return (
    <>
      <Helmet>
        <title>{configs.name}</title>
      </Helmet>
      <ApplicationProvider>
        <HubSpotTracker />
        <Routes />
      </ApplicationProvider>
    </>
  );
}

export default App;
