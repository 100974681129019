/* eslint-disable import/no-anonymous-default-export */
import axios from "../utils/axios";
import ApiTokenSupport from "./token.support";

class NotesApi extends ApiTokenSupport {
  async fetchNote(id: number, userID?: number): Promise<any> {
    const resp = await axios.post(`/api/mortgageNotes/note/fetchById`, {
      noteID: id,
      origin: "income-note-exchange",
      ...(userID && { user_id: userID }),
    });

    return resp.data;
  }

  async fetchSavedNotes(userID: number): Promise<any> {
    const resp = await axios.post(
      `/api/mortgageNotes/savedNote/fetchByUserId`,
      {
        user_id: userID,
        origin: "income-note-exchange",
      },
      this.withAuthorization()
    );
    return resp.data;
  }

  async toggleSavedNote(noteID: number, userID: number): Promise<any> {
    const resp = await axios.post(
      `/api/mortgageNotes/savedNote/toggle`,
      {
        note_id: noteID,
        user_id: userID,
        origin: "income-note-exchange",
      },
      this.withAuthorization()
    );
    return resp.data;
  }
}

export default new NotesApi();
