/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { incomeNoteApi, notesApi } from "../api";
import { FullMortgageNote } from "../interfaces/mortgageNote.interface";
import useAuth from "../hooks/useAuth";
import LoadingTable from "../components/loading/LoadingTable";
import IncomeNotePage from "../components/pages/IncomeNotePage";
import MortgageNotePage from "../components/pages/MortgageNotePage";
import { unhashNote } from "../utils/hashNote";
import { FullIncomeNote } from "../interfaces/incomeNote.interface";

interface NoteProps {
  noteHash: string;
}

export type NoteType = "income" | "mortgage";

const Note = ({ noteHash }: NoteProps) => {
  const [note, setNote] = useState<FullMortgageNote | FullIncomeNote>();

  const { currentUser, isLoading } = useAuth();
  const [isFetchingNote, setIsFetchingNote] = useState<boolean>(false);

  const { noteID, noteType } = unhashNote(noteHash);

  useEffect(() => {
    if (isLoading) return;

    const fetchNote = async () => {
      try {
        setIsFetchingNote(true);

        if (noteType === "income") {
          const incomeNote = await incomeNoteApi.fetchIncomeNote(
            noteID,
            currentUser?.id
          );
          setNote(incomeNote);
        } else if (noteType === "mortgage") {
          const mortgageNote = await notesApi.fetchNote(
            noteID,
            currentUser?.id
          );
          setNote(mortgageNote);
        } else {
          throw new Error("Invalid note type");
        }
      } catch (err: any) {
        console.error(err);
      } finally {
        setIsFetchingNote(false);
      }
    };

    fetchNote();
  }, [noteHash, isLoading]);

  const isPageLoading = isLoading || isFetchingNote;

  if (isPageLoading) {
    return (
      <div className="flex justify-center items-center w-full h-screen">
        <LoadingTable label="Income Note" />
      </div>
    );
  }

  if (!note) {
    return <></>;
  }

  if (noteType === "income") {
    return <IncomeNotePage incomeNote={note as FullIncomeNote} />;
  }

  if (noteType === "mortgage") {
    return <MortgageNotePage note={note as FullMortgageNote} />;
  }

  return <>Invalid note type</>;
};

export default Note;
