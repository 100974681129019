import React, {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useReducer,
} from "react";
import jwt_decode from "jwt-decode";
import { userApi } from "../api";
import { RegisterData } from "../interfaces/user.interface";
import { User } from "../interfaces/user.interface";
import { useTrackingCode } from "react-hubspot-tracking-code-hook";
// import useAlert from "src/hooks/useAlert";

const TOKEN_ID = "x-notes-access-token";

interface AuthState {
  currentUser: User | null;
  redirectTo?: string;
  isLoading: boolean; // New loading state
}

interface AuthProviderProps {
  children: ReactNode;
}

interface AuthContextValue extends AuthState {
  login: (email: string, password: string) => Promise<any>;
  logout: () => void;
  register: (data: RegisterData) => Promise<any>;
  setRedirection: (url: string) => void;
}

const initialAuthState: AuthState = {
  currentUser: null,
  isLoading: true,
};

type InitializeAction = {
  type: "INITIALIZE";
  payload: {
    currentUser: User | null;
    isLoading: boolean;
  };
};

type LoginAction = {
  type: "LOGIN";
  payload: {
    currentUser: User | null;
    isLoading?: boolean;
  };
};

type LogoutAction = {
  type: "LOGOUT";
  payload?: {};
};

type RegisterAction = {
  type: "REGISTER";
  payload: {
    currentUser: User | null;
  };
};

type RedirectAction = {
  type: "REDIRECT";
  payload: string;
};

type Action =
  | InitializeAction
  | LoginAction
  | LogoutAction
  | RegisterAction
  | RedirectAction;

const setSession = (token: string | null): void => {
  if (token) {
    // axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    localStorage.setItem(TOKEN_ID, token);
  } else {
    localStorage.setItem(TOKEN_ID, "");
    // delete axios.defaults.headers.common.Authorization;
  }
};

const decodeToken = (token: string | null) => {
  if (token) {
    const decoded: any = jwt_decode(token);
    const expireAt = new Date(decoded.exp * 1000);
    const currentTime = new Date();
    if (expireAt > currentTime) {
      return decoded.user;
    }
  }
  return null;
};

const handlers = {
  INITIALIZE: (state: AuthState, action: InitializeAction): AuthState => {
    const { currentUser, isLoading } = action.payload;
    return {
      ...state,
      currentUser,
      isLoading,
    };
  },
  LOGIN: (state: AuthState, action: LoginAction) => {
    const { currentUser, isLoading = false } = action.payload;
    return {
      ...state,
      currentUser,
      isLoading,
    };
  },
  LOGOUT: (state: AuthState) => ({
    ...state,
    currentUser: null,
  }),
  REGISTER: (state: AuthState, action: RegisterAction) => {
    const { currentUser } = action.payload;
    return {
      ...state,
      currentUser,
    };
  },
  REDIRECT: (state: AuthState, action: RedirectAction) => {
    return {
      ...state,
      redirectTo: action.payload,
    };
  },
};

const reducer = (state: AuthState, action: Action): AuthState =>
  // @ts-ignore
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  setRedirection: () => {},
});

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const { setIdentity } = useTrackingCode();

  useEffect(() => {
    const initialize = async () => {
      try {
        const token = localStorage.getItem(TOKEN_ID);
        const currentUser = decodeToken(token);

        if (currentUser) {
          setIdentity(currentUser.email);
          dispatch({
            type: "INITIALIZE",
            payload: {
              currentUser,
              isLoading: false,
            },
          });
        } else {
          setSession(null);
          dispatch({
            type: "INITIALIZE",
            payload: {
              currentUser: null,
              isLoading: false,
            },
          });
        }
      } catch (err) {
        dispatch({
          type: "INITIALIZE",
          payload: {
            currentUser: null,
            isLoading: false,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (email: string, password: string) => {
    try {
      dispatch({
        type: "LOGIN",
        payload: {
          currentUser: null,
          isLoading: true,
        },
      });

      const token = await userApi.login(email, password);
      const currentUser = decodeToken(token);
      setSession(token);

      dispatch({
        type: "LOGIN",
        payload: {
          currentUser,
          isLoading: false,
        },
      });

      return token;
    } catch (err: any) {
      dispatch({
        type: "LOGIN",
        payload: {
          currentUser: null,
          isLoading: false,
        },
      });

      console.error("App login: problem logging", err);
      // setAlert({
      //   display: true,
      //   message: err?.message || "Unable to login",
      //   type: "error",
      // });
    }
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: "LOGOUT" });
  };

  const register = async (data: any) => {
    try {
      const { success, token, error } = await userApi.register(data);
      if (success) {
        const currentUser = decodeToken(token);

        setSession(token);

        dispatch({
          type: "REGISTER",
          payload: {
            currentUser,
          },
        });
        return { success, token, error };
      } else {
        console.error("App register: problem registering", error);
        // setAlert({
        //   display: true,
        //   message: error || "Unable to register",
        //   type: "error",
        // });
      }
    } catch (err: any) {
      console.error("App register: problem registering", err);
      // setAlert({
      //   display: true,
      //   message: err?.message || "Unable to register",
      //   type: "error",
      // });
    } finally {
    }
  };

  const setRedirection = useCallback((url: string) => {
    dispatch({
      type: "REDIRECT",
      payload: url,
    });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        logout,
        register,
        setRedirection,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
