export interface AddressComponents {
  houseNumber: string;
  preDirection: string;
  street: string;
  postDirection: string;
  unitNumber: string;
  unitType: string;
  city: string;
  state: string;
  zip: string;
}

const parseAddressComponents = async (
  placeId: string
): Promise<AddressComponents> => {
  const geocoder = new google.maps.Geocoder();
  const result = await new Promise<google.maps.GeocoderResult>(
    (resolve, reject) => {
      geocoder.geocode({ placeId }, (results, status) => {
        if (status === "OK" && results?.[0]) {
          resolve(results[0]);
        } else {
          reject(new Error("Geocoding failed"));
        }
      });
    }
  );

  const components = result.address_components;
  const mapping: { [key: string]: string } = {};

  components.forEach((component) => {
    component.types.forEach((type) => {
      mapping[type] = component.short_name;
    });
  });

  const streetNumber = mapping["street_number"] || "";
  const streetName = mapping["route"] || "";
  const streetParts = streetName.split(" ");

  const directions = ["N", "S", "E", "W", "NE", "NW", "SE", "SW"];
  let preDirection = "";
  let postDirection = "";

  if (directions.includes(streetParts[0])) {
    preDirection = streetParts.shift() || "";
  }
  if (directions.includes(streetParts[streetParts.length - 1])) {
    postDirection = streetParts.pop() || "";
  }

  return {
    houseNumber: streetNumber,
    preDirection,
    street: streetParts.join(" "),
    postDirection,
    unitNumber: mapping["subpremise"] || "",
    unitType: "Unit",
    city: mapping["locality"] || "",
    state: mapping["administrative_area_level_1"] || "",
    zip: mapping["postal_code"] || "",
  };
};

export { parseAddressComponents };
