import React from "react";
import ActionButton from "../components/buttons/ActionButton";
import HeaderNav from "../components/nav/HeaderNav";

interface AcknowledgementProps {
  props?: any;
}

const Acknowledgement = ({ props }: AcknowledgementProps) => {
  return (
    <>
      <HeaderNav />
      <div className="flex flex-col items-center justify-start mb-12 w-full bg-white  px-4 md:px-12 lg:px-96 ">
        <div className="flex flex-col items-center justify-center text-brown">
          <div className="text-sm sm:text-base md:text-lg">
            <p className="underline font-bold text-[20px]">Privacy Policy and Terms of Use</p>
            <br />
            <p>
              Unless stated otherwise in a specific purchase transaction,
              Secured Investment Corp and its members, officers, directors,
              owners, employees, agents, representatives, and affiliates
              (collectively referred to as “us,” “we,” or “the Company”)
              provides this website for information purposes only. By using this
              website you are subject to our terms of use and privacy policy.
              Please review before proceeding.
            </p>
            <br />

            <p className="underline">No Ownership or Control</p>
            <br />
            <p>
              The Company does not own, control, or have any rights to the Notes
              listed on this website, or their corresponding collateral (whereas
              “Note/Notes” are defined as a promissory note, loan documentation,
              its underlying collateral, and its securitization, and any
              information or materials regarding the performance, value,
              history, position, priority status, or worthiness in any respect).
              By using this website, you understand that any Note purchases will
              be between you and an independent third-party that possesses no
              relationship with the Company.
            </p>

            <br />

            <p className="underline">No Examination</p>
            <br />
            <p>
              This website allows any third-party to list certain types of Notes
              and corresponding collateral for sale. The Company does not
              investigate, review, examine, inspect, or assess the Note
              information displayed on this website. There is no agency
              relationship between the Company and any third parties who list
              Notes for sale on this website. While the data we use from third
              parties is believed to be reliable, we cannot ensure the accuracy
              or completeness of data provided by third parties. We do not make
              any representations as to the accuracy or completeness of the
              information contained on this website or provided by third parties
              and undertake no obligation to update the information.
            </p>

            <br />

            <p className="underline">All Notes Offered “As-Is”</p>
            <br />

            <p>
              Any Notes listed on this website are offered "AS IS" AND "WITH ALL
              FAULTS," WITHOUT ANY REPRESENTATION, WARRANTY OR RECOURSE
              WHATSOEVER AS TO EITHER COLLECTIBILITY, CONDITION, FITNESS FOR ANY
              PARTICULAR PURPOSE, MERCHANTABILITY OR ANY OTHER WARRANTY, EXPRESS
              OR IMPLIED. SELLER SPECIFICALLY DISCLAIMS ANY WARRANTY, GUARANTY
              OR REPRESENTATION, ORAL OR WRITTEN, PAST OR PRESENT, EXPRESS OR
              IMPLIED, CONCERNING THE NOTES, SECURITY INSTRUMENTS, THE
              STRATIFICATION OR PACKAGING OF THE NOTES, THE COLLATERAL PROPERTY,
              OR THE NOTE FILES.
            </p>

            <br />

            <p className="underline">
              Purchase Funds Paid Directly to Third Parties
            </p>
            <br />
            <p>
              Funds used to purchase Note packages from this website are paid
              directly to the third-party owner of the Note upon purchase, and
              these purchase funds are not by the Company, or an FDIC-insured or
              held by a bank, depository, or third-party escrow.
            </p>

            <br />

            <p className="underline">
              Conduct your Own Independent Investigation
            </p>
            <br />
            <p>
              All investments contain risk and may lose value. The Company is
              not a registered investment advisor, and as such, does not hold
              itself out to be. If you are seeking financial advice, please
              consult a competent advisor. You should not make any decision
              about purchasing a Note without first conducting your own
              independent due diligence on the specific Note you are interested
              in funding. No communication from the Company through this website
              or any other materials is intended to be or should be construed as
              investment, tax, financial, accounting or legal advice.
            </p>
            <br />

            <br />

            <p>
              By continuing to use this website, you represent you are a
              sophisticated investor and any decision to purchase a Note is
              based upon your own independent expert evaluation of the Note
              files and other material and information deemed relevant by your
              and/or your agents.
            </p>
            <br />

            <p className="underline">
              Note Purchasing Involves Inherent Financial Risk
            </p>
            <br />
            <p>
              Past performance is not an indicator of any future results. All
              investments contain risk and may lose value. Any historical
              returns, target returns, expected returns or probability
              projections may not reflect actual future performance. Users of
              this website should not rely on forward-looking statements because
              such statements are inherently uncertain and involve risks.
            </p>

            <br />

            <div className="w-72 mt-5 shadow-lg ">
              <ActionButton
                onClick={() => window.close()}
                label={"Agree & Close Tab"}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Acknowledgement;
