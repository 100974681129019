import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { AppDispatch, RootState } from "../redux/store";
import {
  fetchMortgageNotes,
  updateFiltersAndFetch,
} from "../redux/slices/mortgageNotesSlice";
import { NoteStatus } from "../interfaces/mortgageNote.interface";
import NoteCardGrid from "../components/notes/NoteCardGrid";
import FilterToolbar from "../components/notes/FilterToolbar";
import NotesHeader from "../components/notes/NotesHeader";

const Notes = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();

  const { notes, status, hasMore, filters } = useSelector(
    (state: RootState) => state.mortgageNotes
  );

  const loadMoreNotes = useCallback(() => {
    if (status === "idle" && hasMore) {
      dispatch(fetchMortgageNotes({ ...filters, offset: notes.length }));
    }
  }, [dispatch, filters, hasMore, notes.length, status]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight - 100
      ) {
        loadMoreNotes();
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [loadMoreNotes]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    const addressState = queryParams.get("addressState")?.split(",") || [];
    const targetMin = Number(queryParams.get("targetMin")) || undefined;
    const targetMax = Number(queryParams.get("targetMax")) || undefined;
    const loanMin = Number(queryParams.get("loanMin")) || undefined;
    const loanMax = Number(queryParams.get("loanMax")) || undefined;
    const status = queryParams.get("status")?.split(",") || [];
    const search = queryParams.get("search") || "";

    const newFilters = {
      addressState,
      targetMin,
      targetMax,
      loanMin,
      loanMax,
      status: status as NoteStatus[],
      offset: 0,
      limit: 10,
      searchQuery: search,
    };

    dispatch(
      updateFiltersAndFetch({
        filters: newFilters,
        navigate,
      })
    );
  }, [dispatch, location.search, navigate]);

  return (
    <>
      <div className="w-full px-4">
        <div>
          <NotesHeader />
          <div className="my-5"></div>
          <FilterToolbar />
        </div>
        <div className="w-full flex justify-center ">
          <NoteCardGrid notes={notes} status={status} />
        </div>
      </div>
    </>
  );
};

export default Notes;
