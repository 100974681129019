import React, { useEffect } from "react";
import usePlacesAutocomplete from "use-places-autocomplete";
import { motion, AnimatePresence } from "framer-motion";

interface AddressAutocompleteProps {
  value: string;
  onChange: (address: google.maps.places.AutocompletePrediction | null) => void;
  hasError?: boolean;
  onValidationChange?: (isValid: boolean) => void;
}

export const AddressAutocomplete = ({
  value,
  onChange,
  hasError,
  onValidationChange,
}: AddressAutocompleteProps) => {
  const {
    ready,
    suggestions: { status, data },
    setValue: setPlacesValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: { country: "us" },
      types: ["address"],
    },
    debounce: 300,
    defaultValue: value,
  });

  useEffect(() => {
    // Notify parent about validation state when value changes
    if (onValidationChange) {
      onValidationChange(!!value);
    }
  }, [value, onValidationChange]);

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setPlacesValue(newValue);
    onChange({ 
      description: newValue,
      place_id: '',
      structured_formatting: {
        main_text: '',
        secondary_text: '',
        main_text_matched_substrings: []
      },
      matched_substrings: [],
      terms: [],
      types: []
    });
  };

  return (
    <div className="relative">
      <input
        type="text"
        value={value}
        onChange={handleInput}
        disabled={!ready}
        placeholder="Enter address..."
        className={`input input-sm w-full ${hasError ? "border-error" : ""}`}
        data-1p-ignore
      />
      
      <AnimatePresence>
        {status === "OK" && (
          <motion.ul
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            className="absolute z-10 w-full bg-base-100 shadow-lg rounded-lg mt-1 max-h-60 overflow-auto"
          >
            {data.map((suggestion) => (
              <motion.li
                key={suggestion.place_id}
                layout
                onClick={() => {
                  setPlacesValue(suggestion.description, false);
                  onChange(suggestion);
                  clearSuggestions();
                }}
                className="p-2 hover:bg-base-200 cursor-pointer"
              >
                {suggestion.description}
              </motion.li>
            ))}
          </motion.ul>
        )}
      </AnimatePresence>
      {hasError && (
        <div className="text-error text-sm mt-1">
          This field is required
        </div>
      )}
    </div>
  );
}; 