import React, { useState, useRef } from "react";
import { SwiperSlide } from "swiper/react";
import LoadingSpinner from "../../loading/LoadingSpinner";
import { PasswordFormErrors } from "./LoginRegisterModal";
import { Link } from "react-router-dom";

interface PasswordSlideProps {
  passwordData: { password: string; repeatPassword: string };
  passwordErrors: PasswordFormErrors;
  handlePasswordChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  submitPassword: () => void;
  isLoading: boolean;
}

const PasswordSlide = ({
  passwordData,
  passwordErrors,
  handlePasswordChange,
  submitPassword,
  isLoading,
}: PasswordSlideProps) => {
  const [acknowledgement, setAcknowledgement] = useState<boolean>(false);
  const checkboxRef = useRef<HTMLLabelElement>(null);

  const handleAcknowledgement = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAcknowledgement(e.target.checked);
  };

  const attentionAcknowledgement = () => {
    if (checkboxRef.current) {
      checkboxRef.current.classList.add("animate-attention");
      setTimeout(() => {
        checkboxRef.current?.classList.remove("animate-attention");
      }, 820); // Slightly longer than the animation duration
    }
  };

  const handleAcknowledgementClick = async (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.preventDefault(); // Prevent the default link behavior

    window.open(e.currentTarget.href, "_blank", "noopener,noreferrer");
    await new Promise((resolve) => setTimeout(resolve, 1000));
    setAcknowledgement(true);

    // Open the link in a new tab
  };

  return (
    <>
      <SwiperSlide>
        <div className="flex flex-col items-center justify-center h-full w-full xs:px-4 sm:px-12 md:px-28 -mt-5">
          <div className="w-full  max-w-96 mb-1">
            <input
              type="password"
              name="password"
              placeholder="Create Password"
              className="input input-bordered border-light-brown focus:border-light-brown placeholder:text-light-brown bg-white  text-brown w-full"
              value={passwordData.password}
              onChange={handlePasswordChange}
            />
            {passwordErrors.password && (
              <p className="text-red-500 text-sm">{passwordErrors.password}</p>
            )}
          </div>
          <div className="w-full  max-w-96">
            <input
              type="password"
              name="repeatPassword"
              placeholder="Repeat Password"
              className="input input-bordered border-light-brown focus:border-light-brown placeholder:text-light-brown bg-white  text-brown w-full"
              value={passwordData.repeatPassword}
              onChange={handlePasswordChange}
            />
          </div>{" "}
          {passwordErrors.repeatPassword ? (
            <p className="text-red-500 text-sm my-2">
              {passwordErrors.repeatPassword}
            </p>
          ) : (
            <div className="flex flex-row w-full justify-center items-center max-w-96">
              <label
                ref={checkboxRef}
                className="cursor-pointer label flex flex-row items-center justify-start gap-2 transition-transform w-full"
              >
                <input
                  type="checkbox"
                  className="checkbox checkbox-secondary checkbox-sm"
                  checked={acknowledgement}
                  onChange={handleAcknowledgement}
                />
                <div className="label-text text-brown">
                  I agree to the{" "}
                  <Link
                    to={"/terms-and-conditions"}
                    target="_blank"
                    rel="noreferrer"
                    className="text-secondary font-extrabold hover:underline"
                    onClick={handleAcknowledgementClick}
                  >
                    Terms and Conditions
                  </Link>
                </div>
              </label>
            </div>
          )}
          <div className="w-full  max-w-96">
            <button
              className="btn btn-accent w-full text-brown font-extrabold"
              onClick={
                acknowledgement ? submitPassword : attentionAcknowledgement
              }
            >
              {isLoading ? <LoadingSpinner /> : "REGISTER"}
            </button>
          </div>
        </div>
      </SwiperSlide>
    </>
  );
};

export default PasswordSlide;
