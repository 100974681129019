import React from "react";
import LoadingSpinner from "../../loading/LoadingSpinner";

interface LoginSlideProps {
  loginData: { email: string; password: string };
  loginErrors: { email: string; password: string };
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  submitLogin: () => void;
  goToNextSlide: () => void;
  isLoading: boolean;
}

const LoginSlide = ({
  loginErrors,
  loginData,
  handleChange,
  submitLogin,
  goToNextSlide,
  isLoading,
}: LoginSlideProps) => {
  return (
    <>
      <div className="flex flex-col items-center justify-center h-full gap-2 w-full xs:px-4 sm:px-12 md:px-28 ">
        <div className="w-full max-w-96">
          <input
            type="text"
            name="email"
            placeholder="Email"
            className={`input input-bordered ${
              loginErrors.email ? "border-red-500" : "border-light-brown"
            } focus:border-light-brown placeholder:text-light-brown bg-white  text-brown w-full`}
            value={loginData.email}
            onChange={handleChange}
          />
          {loginErrors.email && (
            <p className="text-red-500 text-sm">{loginErrors.email}</p>
          )}
        </div>
        <div className="w-full  max-w-96">
          <input
            type="password"
            name="password"
            placeholder="Password"
            className={`input input-bordered ${
              loginErrors.password ? "border-red-500" : "border-light-brown"
            } focus:border-light-brown placeholder:text-light-brown bg-white  text-brown w-full`}
            value={loginData.password}
            onChange={handleChange}
          />
          {loginErrors.password && (
            <p className="text-red-500 text-sm">{loginErrors.password}</p>
          )}
        </div>
        <div className="w-full  max-w-96">
          <button
            className="btn btn-accent w-full text-brown"
            onClick={submitLogin}
          >
            {isLoading ? <LoadingSpinner /> : "LOGIN"}
          </button>
        </div>
        <div className="my-2 mt-6">
          <p className="text-main-gray">
            Don't have an account?{" "}
            <span
              className="text-main-gray underline cursor-pointer font-semibold"
              onClick={goToNextSlide}
            >
              Register
            </span>
          </p>
        </div>
      </div>
    </>
  );
};

export default LoginSlide;
