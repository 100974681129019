import React, { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UnitedStatesState } from "../../data/unitedStates";
import capitalize from "../../helpers/capitalize";
import states from "../../data/unitedStates";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/pro-solid-svg-icons";
import FilterValueRange, { Option } from "./FilterValueRange";
import { AppDispatch, RootState } from "../../redux/store";
import { updateFiltersAndFetch } from "../../redux/slices/mortgageNotesSlice";
import { useNavigate } from "react-router-dom";

const FilterToolbar: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const filters = useSelector(
    (state: RootState) => state.mortgageNotes.filters
  );

  const [selectedStates, setSelectedStates] = useState<string[]>(
    filters.addressState || []
  );
  const [targetROR, setTargetROR] = useState<
    [number | undefined, number | undefined]
  >([filters.targetMin, filters.targetMax]);
  const [loanAmount, setLoanAmount] = useState<
    [number | undefined, number | undefined]
  >([filters.loanMin, filters.loanMax]);

  const handleStateFilter = useCallback(
    (state: string) => {
      const updatedStates = selectedStates.includes(state)
        ? selectedStates.filter((s) => s !== state)
        : [...selectedStates, state];
      setSelectedStates(updatedStates);
      dispatch(
        updateFiltersAndFetch({
          filters: {
            addressState: updatedStates.length > 0 ? updatedStates : undefined,
          },
          navigate,
        })
      );
    },
    [selectedStates, dispatch, navigate]
  );

  const handleTargetRORChange = useCallback(
    (min: number | undefined, max: number | undefined) => {
      setTargetROR([min, max]);
      dispatch(
        updateFiltersAndFetch({
          filters: {
            targetMin: min !== undefined ? min : undefined,
            targetMax: max !== undefined ? max : undefined,
          },
          navigate,
        })
      );
    },
    [dispatch, navigate]
  );

  const handleLoanAmountChange = useCallback(
    (min: number | undefined, max: number | undefined) => {
      setLoanAmount([min, max]);
      dispatch(
        updateFiltersAndFetch({
          filters: {
            loanMin: min !== undefined ? min : undefined,
            loanMax: max !== undefined ? max : undefined,
          },
          navigate,
        })
      );
    },
    [dispatch, navigate]
  );

  return (
    <div className="w-full space-y-4 mt-1 mb-4">
      <div className="flex justify-between w-full gap-4">
        <div className="dropdown cursor-pointer flex-1">
          <div
            tabIndex={0}
            role="button"
            className="btn btn-outline w-full mb-0.5 font-bold relative hover:bg-white hover:border-secondary text-main-gray text-xs sm:text-sm md:text-lg rounded-[6px]"
          >
            Select State(s)
            <div className="absolute right-2">
              <FontAwesomeIcon icon={faAngleDown} />
            </div>
          </div>
          <ul
            tabIndex={0}
            className="menu dropdown-content z-50 bg-brown rounded-lg w-72 max-h-60 overflow-y-auto"
            style={{ width: "calc(100vw - 30px)" }}
          >
            {states.map((state: UnitedStatesState, index: number) => (
              <label
                key={`united-states-state-${index}`}
                className="flex items-center pr-4"
              >
                <input
                  type="checkbox"
                  className="checkbox checkbox-xs checked:checkbox-accent"
                  checked={selectedStates.includes(state.value)}
                  onChange={() => handleStateFilter(state.value)}
                />
                <span className="ml-2 select-none cursor-pointer text-very-light-brown">
                  {capitalize(state.name)}
                </span>
              </label>
            ))}
          </ul>
        </div>

        <div className="dropdown cursor-pointer flex-1">
          <div
            tabIndex={0}
            role="button"
            className="btn btn-outline w-full mb-0.5 font-bold relative hover:bg-white hover:border-secondary text-main-gray text-xs sm:text-sm md:text-lg rounded-[6px]"
          >
            Targeted ROR
            <div className="absolute right-2">
              <FontAwesomeIcon icon={faAngleDown} />
            </div>
          </div>
          <ul className="menu dropdown-content z-50 bg-brown rounded-lg">
            <FilterValueRange
              minValues={rorMinValues}
              maxValues={rorMaxValues}
              state={{ minValue: targetROR[0], maxValue: targetROR[1] }}
              setState={(newState: {
                minValue: number | undefined;
                maxValue: number | undefined;
              }) => {
                handleTargetRORChange(newState.minValue, newState.maxValue);
              }}
            />
          </ul>
        </div>

        <div className="dropdown cursor-pointer flex-1 dropdown-end">
          <div
            tabIndex={0}
            role="button"
            className="btn btn-outline w-full mb-0.5 font-bold relative hover:bg-white hover:border-secondary text-main-gray text-xs sm:text-sm md:text-lg rounded-[6px]"
          >
            Loan Amount
            <div className="absolute right-2">
              <FontAwesomeIcon icon={faAngleDown} />
            </div>
          </div>
          <ul className="menu dropdown-content z-50 bg-brown rounded-lg">
            <FilterValueRange
              minValues={loanAmountMinValues}
              maxValues={loanAmountMaxValues}
              state={{ minValue: loanAmount[0], maxValue: loanAmount[1] }}
              setState={(newState: {
                minValue: number | undefined;
                maxValue: number | undefined;
              }) => {
                handleLoanAmountChange(newState.minValue, newState.maxValue);
              }}
            />
          </ul>
        </div>
      </div>
    </div>
  );
};

export default FilterToolbar;

const rorMinValues: Option[] = [
  {
    value: 0,
    label: "0%",
  },
  {
    value: 1,
    label: "1%",
  },
  {
    value: 2,
    label: "2%",
  },
  {
    value: 3,
    label: "3%",
  },
  {
    value: 4,
    label: "4%",
  },
  {
    value: 5,
    label: "5%",
  },
  {
    value: 6,
    label: "6%",
  },
  {
    value: 7,
    label: "7%",
  },
  {
    value: 8,
    label: "8%",
  },
  {
    value: 9,
    label: "9%",
  },
  {
    value: 10,
    label: "10%",
  },
];

const rorMaxValues: Option[] = [
  {
    value: 0,
    label: "0%",
  },
  {
    value: 1,
    label: "1%",
  },
  {
    value: 2,
    label: "2%",
  },
  {
    value: 3,
    label: "3%",
  },
  {
    value: 4,
    label: "4%",
  },
  {
    value: 5,
    label: "5%",
  },
  {
    value: 6,
    label: "6%",
  },
  {
    value: 7,
    label: "7%",
  },
  {
    value: 8,
    label: "8%",
  },
  {
    value: 9,
    label: "9%",
  },
  {
    value: 10,
    label: "10%",
  },
];

// Add loan amount min and max values
const loanAmountMinValues: Option[] = [
  { value: 0, label: "$0" },
  { value: 50000, label: "$50,000" },
  { value: 100000, label: "$100,000" },
  // ... add more options as needed
];

const loanAmountMaxValues: Option[] = [
  { value: 50000, label: "$50,000" },
  { value: 100000, label: "$100,000" },
  { value: 200000, label: "$200,000" },
  // ... add more options as needed
];
